.container {
  display: flex;
  justify-content: center;
}

.image {
  display: block;
  width: 100%;
}

.image[data-layout="constrained"] {
  max-width: 1200px;
}
