.list {
  display: block;
}

.item {
  --_vertical-padding: 12px;

  display: block;
}

.item:not(:last-child) {
  border-bottom: var(--border-dotted);
  padding-bottom: var(--_vertical-padding);
}

.item:not(:first-child) {
  padding-top: var(--_vertical-padding);
}

.link:is(:hover, :active, :focus) span {
  color: var(--color-text-muted);
  text-decoration-line: var(--link-text-decoration-line);
  text-decoration-style: var(--link-text-decoration-style);
  text-decoration-color: var(--link-text-decoration-color);
  text-underline-offset: var(--link-text-underline-offset);
}
