.carousel {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;

  display: flex;
  position: relative;
  flex-direction: column-reverse;
  z-index: 1;
  margin: auto;
  padding-inline: var(--container-padding-inline);
  width: 100%;
}

.viewport {
  overflow: hidden;
}

.slide-container {
  display: flex;
  backface-visibility: hidden;
  margin-left: calc(var(--slide-spacing) * -1);
  touch-action: pan-y;
}

.slide {
  display: flex;
  flex: 0 0 var(--slide-size);
  justify-content: center;
  padding-left: var(--slide-spacing);
  min-width: 0;
}

.slide img {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 1200px;
  object-fit: contain;
}

.controls {
  width: 100%;
}

.control-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

/* Visually hidden - only show with keyboard controls */
.control-buttons:not(:focus-within) {
  clip: rect(0 0 0 0);
  position: absolute;
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Only underline text, not arrow */
.button:not(:disabled):is(:hover, :active, :focus-visible) span {
  text-decoration-line: var(--link-text-decoration-line);
  text-decoration-style: var(--link-text-decoration-style);
  text-decoration-color: var(--link-text-decoration-color);
  text-underline-offset: var(--link-text-underline-offset);
}

.button:disabled {
  /* cursor: not-allowed; */
  color: var(--color-grey);
}

.button--next {
  top: 0;
  left: 0;
}

.button--prev {
  top: 0;
  right: 0;
}

/* wrapper around carousel to trigger and contain visual only text animations */
.hover-area {
  --opacity: 0;
  --cursor: default;

  position: relative;
  cursor: none;
  /* cursor: var(--cursor); */
}

.hover-area:is(:hover) {
  --opacity: 1;
}

/* JS controlled, if cursor is in certain area */
.hover-area[data-prev-or-next="prev"],
.hover-area[data-prev-or-next="next"] {
  /* --cursor: pointer; */
}

.hover-area[data-prev-or-next="prev"][data-prev-disabled="true"],
.hover-area[data-prev-or-next="next"][data-next-disabled="true"] {
  /* --cursor: not-allowed; */
}

.hover-area[data-prev-disabled="true"] .hover-label__button--prev,
.hover-area[data-next-disabled="true"] .hover-label__button--next {
  color: var(--color-grey);
}

.hover-label {
  --next-opacity: 0; /* Updated via JS */
  --prev-opacity: 0; /* Updated via JS */
  --left: 0; /* Updated via JS */
  --top: 0; /* Updated via JS */

  position: absolute;
  transform: translate3d(var(--left, 0), var(--top, 0), 0);
  opacity: var(--opacity, 0);
  z-index: 1;
  transition: opacity 400ms ease;
  pointer-events: none; /* don't block carousel click events */
}

.hover-label__button {
  --_width: 11.3344415898ch; /* magic number for fluid type = 150px*/

  display: flex;
  display: flex;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition-duration: 200ms;
  transition-property: color, opacity;
  transition-timing-function: ease;
  border-radius: 9999px;
  background: white;
  width: var(--_width);
  height: var(--_width);
  white-space: nowrap;
}

.hover-label__button--next {
  top: calc(var(--_width) / -2);
  left: calc(var(--_width) / -2);
  opacity: var(--next-opacity, 0);
}

.hover-label__button--prev {
  top: calc(var(--_width) / -2);
  left: calc(var(--_width) / -2);
  opacity: var(--prev-opacity, 0);
}

.navigation-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px;
  margin-top: 24px;
  margin-inline: auto;
}

.navigation-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.navigation-dot::before {
  transition-duration: 200ms;
  transition-property: width, height, background-color;
  transition-timing-function: linear;
  margin: auto;
  border-radius: 4px;
  background-color: black;
  width: 4px;
  height: 4px;
  content: "";
}

.navigation-dot[data-selected]::before {
  border-radius: 8px;
  background-color: grey;
  width: 8px;
  height: 8px;
}

.navigation-dot-label {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  margin: 0;
  padding: 0;
  width: 1px;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
}
