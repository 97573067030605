.container {
  padding-inline: var(--container-padding-inline);
}

.heading {
  margin-bottom: 32px;
}

.members {
  /**
   *  Cols have a min width, up to X cols per row.
   */

  /* settings */
  --_min-column-size: 200px;
  --_column-count: 5;
  --_gap: 20px;

  /* calculations */
  --_column-size: calc((100% / var(--_column-count)) - var(--_gap));

  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(max(var(--_min-column-size), var(--_column-size)), 1fr)
  );
  column-gap: var(--_gap);
  row-gap: 32px;
}

.member {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.name,
.qualifications {
  color: var(--color-text);
  font: var(--font-primary);
}

.name {
  margin-top: 15px;
}

.image-container {
  background-color: var(--color-primary);
  aspect-ratio: 1;
}

.image {
  display: block;
  aspect-ratio: 1;
  width: 100%;
  object-fit: cover;
}
