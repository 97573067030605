.container {
  display: flex;
  padding-inline: var(--container-padding-inline);
}

.image {
  display: block;
  width: 100%;
  max-width: 1200px;
}
