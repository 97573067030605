.container {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  max-width: 1200px;
}

.video-wrapper {
  --_aspect-ratio: var(--aspect-ratio, 9 / 16);

  position: relative;
  background-color: var(--color-black);
  width: 100%;
  overflow: hidden;
}

.video-wrapper:has(.iframe) {
  /* falls back to 16/9, but otherwise uses ratio from HTML */
  padding-bottom: calc(var(--_aspect-ratio) * 100%);
  height: 0;
}

.video {
  display: block;
  width: 100%;
  height: auto;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-cookies {
  display: flex;
  position: relative; /* for icon */
  justify-content: center;
  align-items: center;
  background-color: color-mix(in srgb, var(--color-black) 10%, transparent);
  padding: 32px;
  aspect-ratio: 16 / 9;
  width: 100%;
}

.no-cookies-text {
  z-index: 2;
  font-size: var(--fluid-font-size-20px-24px);
  line-height: var(--line-height-base);
  text-align: center;
}

.no-cookies-icon {
  position: absolute;
  opacity: 0.2;
  z-index: 1;
  margin: auto;
  width: 128px;
  height: 128px;
  color: var(--color-grey);
}

@media (min-width: 400px) {
  .no-cookies-icon {
    width: 256px;
    height: 256px;
  }
}
