.container {
  --_main-min-width: 45ch;
  --_sidebar-size: 200px;
  --_gap: 64px;

  display: flex;
  column-gap: var(--_gap);
  flex-direction: row;
  flex-wrap: wrap;
  padding-inline: var(--container-padding-inline);
}

.heading {
  flex-grow: 1;
  margin-bottom: 18px;
  color: var(--color-text);
  font: var(--font-primary);
  font-size: var(--fluid-font-size-20px-24px);
}

.inner {
  display: flex;
  column-gap: var(--_gap);
  row-gap: 24px;
  flex-wrap: wrap;
}

.inner[data-alignment="left"] {
  margin-inline: 0 auto;
}

.inner[data-alignment="right"] {
  margin-inline: 0 auto;
}

@media (min-width: 1200px) {
  .inner[data-alignment="right"] {
    margin-inline: auto 0;
  }
}

.inner[data-width="full"] {
  flex-basis: 960px;
}

.inner[data-width="three-quarters"] {
  --_main-min-width: 45ch;

  flex-basis: 960px;
}

@media (min-width: 1200px) {
  .inner[data-width="three-quarters"] {
    --_main-min-width: 0px;

    flex-basis: 780px;
  }
}

.subheading {
  flex-grow: 1;
  flex-basis: var(--_sidebar-size);
  color: var(--color-text);
  font: var(--font-primary);
  font-size: var(--fluid-font-size-base);
}

.body {
  flex-grow: 999;
  flex-basis: calc(((var(--_main-min-width) - (100% - var(--_sidebar-size) - var(--_gap))) * 9999));
  font-size: var(--fluid-font-size-base);
}
