.container {
  margin-top: var(--block-spacing);

  &[data-spacing="loose"] {
    --block-spacing: min(220px, 220 / 1728 * 100vw);
  }

  &[data-spacing="tight"] {
    --block-spacing: min(140px, 140 / 1728 * 100vw);
  }

  &[data-no-margin-top="true"] {
    margin-top: unset;
  }
}

.block {
  margin-bottom: var(--block-spacing);
}

/**
 * ---------------------------------------------------------
 * "Magic" spacing values. Ie, special rules and exceptions.
 * ---------------------------------------------------------
 */

.block:is(
    [data-block-type="textBlock"],
    [data-block-type="projectDetailsBlock"],
    [data-block-type="projectDownloadsBlock"]
  ) {
  &:has(+ &) {
    margin-bottom: 40px;
  }
}
