.container {
  /* blank */
}

.headline {
  margin-bottom: 18px;
  padding-inline: var(--container-padding-inline);
  font-weight: normal;
  font-size: var(--fluid-font-size-base);
  line-height: var(--line-height-base);
}

.item {
  /** For sticker positioning, tooltip positioning, and for the link's clickable
   * area to cover the item
   */
  position: relative;
  flex: 1 0 auto;
  isolation: isolate;
  width: 80vw;

  @media (min-width: 768px) {
    width: min(42vw, 740px);
  }
}

.link {
  display: block;
  position: absolute;
  z-index: 1; /* Bring to top */
  inset: 0 0 0 0;
}

.item:has(:is(:hover, :active, :focus)) .image {
  opacity: 0.6;
}

.sticker {
  display: none;
  position: absolute;
  margin: 20px;

  @media (min-width: 768px) {
    display: unset;
  }

  &[data-align="bottom-right"] {
    inset: auto 0 0 auto;
  }

  &[data-align="top-right"] {
    inset: 0 0 auto auto;
  }

  &[data-align="top-left"] {
    inset: 0 auto auto 0;
  }

  &[data-align="bottom-left"] {
    inset: auto auto 0 0;
  }
}

/**
 * On mobile this isn't actually a tooltip, it's just a heading beneath the
 * image. Only at larger viewports does it actually take on "tooltip" behaviour.
 */
.tooltip {
  --_transform: var(--transform);

  margin-top: 4px;
  max-width: 200px;
  font: var(--font-primary);

  @media (min-width: 768px) {
    position: absolute;
    transform: var(--_transform);
    opacity: 0;
    z-index: 1;
    transition: opacity 400ms ease;
    will-change: transform;
    margin-top: unsert;
    background-color: white;

    .item:has(a:is(:hover, :active, :focus)) & {
      opacity: 1;
    }

    &[data-mode="cursor"] {
      top: 0;
      left: 0;
      margin-top: 12px;
      margin-left: 12px;
    }

    &[data-mode="below"] {
      top: 100%;
      left: 0;
      transform: translate3d(0, 0, 0) !important; /* Override inline styles applied by JS */
    }
  }
}

.embla {
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 50%;

  position: relative; /* for buttons */
  margin: auto;
  max-width: 100%;
}

.embla__viewport {
  padding-inline: var(--container-padding-inline);
  overflow: hidden; /* not needed, but set as a reminder for this carousel */
}

.embla__container {
  display: flex;
  column-gap: 32px;
  flex-wrap: nowrap;
  backface-visibility: hidden;
  /* margin-left: calc(var(--slide-spacing) * -1); */
  touch-action: pan-y pinch-zoom;

  @media (min-width: 768px) {
    column-gap: 72px;
  }
}

.footer {
  margin-top: 1rem;
  padding-inline: var(--container-padding-inline);
  width: 100%;
}

.progress {
  background-color: var(--color-grey);
  height: 1px;
}

.progress__bar {
  transform: translateY(-1px);
  transition: max-width 0.3s linear;
  background-color: var(--color-grey);
  width: 100%;
  max-width: calc(var(--_index, 1) * calc(100 / var(--_length, 1) * 1%));
  height: 2px;
}
