.list {
  --_size-unit: var(--font-size-12px);
  --_size-unit-gap: 4px;

  display: flex;
  row-gap: 16px;
  flex-direction: column;

  /* magic number breakpoint */
  @media (width > 350px) {
    --_size-unit: var(--font-size-14px);
    --_size-unit-gap: var(--grid-column-gap);
  }
}

.row {
  display: flex;
  column-gap: 16px; /* should be 64px, but we make the gap smaller for long german text */
  row-gap: 8px;
  flex-wrap: wrap;
}

/**
 * flex basis should be 200px, with a 64px gap.
 * However, the text is too long in german, so the `.row` gap is 16px (not 64px).
 * So make the column `248px` (200px + 64px) - 16px.
 */
.chart-heading {
  flex-grow: 1;
  flex-basis: 248px; /* (200px + 64px) - 16px */
}

.chart-col {
  /* 424px is the min width calculation for `.chart` + 15px padding */
  @media (width > 457px) {
    flex-grow: 999;
    flex-basis: 0;
  }
}

.chart {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  column-gap: var(--_size-unit-gap);
  min-width: 0;

  /* 424px is the min width calculation below + 15px padding */
  @media (width > 457px) {
    grid-template-columns: repeat(10, calc(var(--_size-unit) * 2));
    min-width: calc((var(--_size-unit) * 2 * 10) + (var(--_size-unit-gap) * 9));
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  aspect-ratio: 1;
  width: calc(var(--_size-unit) * 2);
  font-size: var(--_size-unit);
}

.circle[data-active="false"] {
  border: var(--border-dotted);
  color: transparent;
}

.circle:nth-child(1)[data-active="true"] {
  background-color: #ff906d;
}
.circle:nth-child(2)[data-active="true"] {
  background-color: #fe9e6b;
}
.circle:nth-child(3)[data-active="true"] {
  background-color: #ffbc65;
}
.circle:nth-child(4)[data-active="true"] {
  background-color: #ffdd64;
}
.circle:nth-child(5)[data-active="true"] {
  background-color: #fbee1b;
}
.circle:nth-child(6)[data-active="true"] {
  background-color: #eff949;
}
.circle:nth-child(7)[data-active="true"] {
  background-color: #dcf9a0;
}
.circle:nth-child(8)[data-active="true"] {
  background-color: #d0ef8c;
}
.circle:nth-child(9)[data-active="true"] {
  background-color: #aadd6a;
}
.circle:nth-child(10)[data-active="true"] {
  background-color: #96ca2a;
}
