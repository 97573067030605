/* ProjectAwards.module.css */
.container {
  padding-inline: var(--container-padding-inline);
}

.heading {
  margin-bottom: 12px;
  font: var(--font-primary);
  font-size: var(--fluid-font-size-16px-20-px);

  @media (width > 1200px) {
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    margin: -1px;
    border-width: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    white-space: nowrap;
  }
}

.table {
  display: flex;
  column-gap: var(--grid-column-gap);
  flex-direction: column;

  @media (width > 500px) {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  @media (width > 1200px) {
    display: grid;
    grid-template-columns: minmax(auto, 480px) minmax(auto, 80px) minmax(0, 1fr) auto;
  }
}

.table :where(thead, tbody) {
  display: contents;
}

.table :where(th, td) {
  font: var(--font-primary);
  text-align: left;
}

/* Max-Width MQ */
@media (width < 1200px) {
  .table-head {
    display: initial; /* to overwrite `display: contents` */
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    margin: -1px;
    border-width: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    white-space: nowrap;
  }
}

.th-year,
.th-placing {
  text-align: right;
}

.row {
  display: flex;
  position: relative; /* Sets the container for the "clickable row", for the link to the project page. */
  flex-direction: column;
  flex-wrap: wrap;
  transition: opacity 400ms ease;
  padding-block: 12px;

  @media (width > 500px) {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1/-1;
  }
}

.td-award {
  flex-grow: 999;
  flex-basis: 100%;
  order: 3;

  @media (width > 1200px) {
    order: unset;
  }
}
.td-year {
  flex-grow: 1;
  order: 4;

  @media (width > 500px) {
    text-align: right;
  }

  @media (width > 1200px) {
    order: unset;
  }
}

.td-project {
  flex-grow: 999;
  flex-basis: 100%;
  order: 1;

  @media (width > 1200px) {
    order: unset;
  }
}

.td-placing {
  flex-grow: 1;
  order: 2;

  @media (width > 500px) {
    text-align: right;
  }

  @media (width > 1200px) {
    order: unset;
  }
}

thead .row {
  padding-top: 0;
}

tbody .row:last-child {
  padding-bottom: 0;
}

tbody .row {
  border-top: var(--border-dotted);
}

.link {
  display: inline-block;
  margin-bottom: 8px;

  @media (width > 500px) {
    margin-bottom: unset;
  }
}

.link:is(:hover, :active, :focus) {
  color: var(--color-text-muted);
  text-decoration-line: var(--link-text-decoration-line);
  text-decoration-style: var(--link-text-decoration-style);
  text-decoration-color: var(--link-text-decoration-color);
  text-underline-offset: var(--link-text-underline-offset);
}

.link::after {
  display: block;
  position: absolute;
  z-index: 1;
  inset: 0 0 0 0;
  content: "";
}

.winner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  border-radius: 50%;
  background-color: var(--color-primary);
  padding: 0;
  width: 22px;
  height: 22px;
  font-size: var(--font-size-14px);
  text-align: center;

  @media (width > 1200px) {
    margin-block: -7px; /* So that the inner text stays vertically aligned with the other columns */
    width: 40px;
    height: 40px;
    font-size: var(--font-size-18px);
  }
}
