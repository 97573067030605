.container {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-inline: var(--container-padding-inline);

  @media (min-width: 1330px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 108px;
  }
}

.container[data-layout="image-text"] .text {
  order: 1;
}

.container[data-layout="text-image"] .text {
  order: -1;
}

.image {
  order: 0;
}

.image img {
  width: 100%;
  max-width: 960px;
}

.text {
  max-width: 960px;
}
