.container {
  --_circle-width: 920px;
  --_circle-inline-padding: 4%; /* Magic number for mobile, 40px in designs */
  --_padding: var(--container-padding-inline);
  --_content-max-width: 570px;

  display: flex;
  position: relative; /* Sets the container for the `position:sticky` .inner */
  justify-content: center;
  padding-block: var(--container-padding-inline);
  width: 100%;
  min-height: 100lvh;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0; /* Set in JS */
  z-index: -1;
  background-color: var(--color-primary); /* Overridden in JS */
  width: 100%;
  height: 100%;
  pointer-events: none; /* This element covers the whole screen, so we don't want it to inadvertently block click events */

  @media (prefers-reduced-motion) {
    opacity: 0 !important; /* important to override inline JS */
  }
}

.inner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: var(--_circle-inline-padding);
  aspect-ratio: 1/1;
  width: calc(100% - (var(--_padding) * 2));
  max-width: var(--_circle-width);
  height: 100lvh;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Set in JS */
  border: var(--border-dotted);
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 100%;
  content: "";

  @media (prefers-reduced-motion) {
    opacity: 1 !important; /* important to override inline JS */
  }
}

.content {
  opacity: 0; /* Set in JS */
  max-width: var(--_content-max-width);
  quotes: "\201E" "\201D";
  text-align: center;
  text-wrap: balance;
  font: var(--font-primary);
  font-size: var(--fluid-font-size-16px-24px);

  @media (prefers-reduced-motion) {
    opacity: 1 !important; /* important to override inline JS */
  }
}

.content > p::before {
  content: open-quote;
}

.content > p::after {
  content: close-quote;
}
