.list {
  container-type: inline-size;
}

.row {
  --vertical-padding: 12px;

  display: flex;
  flex-direction: column;
}

/* min-width is based on "min cell size + gap" */
@container (min-width: 464px) {
  .row {
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr);
    column-gap: 64px;
  }

  /* Colon is used in stacked layout */
  .colon {
    display: none;
  }
}

.row:not(:last-child) {
  border-bottom: var(--border-dotted);
  padding-bottom: var(--vertical-padding);
}

.row:not(:first-child) {
  padding-top: var(--vertical-padding);
}
