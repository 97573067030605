.container {
  /* user setting */
  --min-column-size: 300px;
  --column-count: 2;
  --gap: var(--grid-column-gap); /* 16px */
  --vertical-overlap: 10lvw;

  container-type: inline-size;
  padding-inline: var(--container-padding-inline);
}

.grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "a"
    "b";
  column-gap: var(--grid-column-gap);
  row-gap: var(--gap);

  @container (min-width: 768px) {
    &[data-layout="top-aligned"] {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas: "a b";
    }

    &[data-layout="offset"] {
      grid-template-rows: auto var(--vertical-overlap) auto;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        "a ."
        "a b"
        ". b";
    }
  }
}

.column {
  &:first-child {
    grid-area: a;
  }

  &:last-child {
    grid-area: b;
  }
}

.image {
  width: 100%;
}
