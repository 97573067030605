.container {
  display: flex;
  padding-inline: var(--container-padding-inline);

  &[data-alignment="right"] {
    justify-content: flex-end;
  }

  &[data-alignment="left"] {
    justify-content: flex-start;
  }
}

.link {
  position: relative; /* Set's the boundaries of the tooltip */
}

.image {
  display: block;
  width: 100%;
  max-width: 1240px;
}

.tooltip {
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: opacity 400ms ease;
  will-change: transform;
  background-color: white;
  padding: 4px;
  max-width: 200px;
  font: var(--font-primary);

  @media (hover: hover) {
    .link:is(:hover, :active, :focus) & {
      opacity: 1;
    }
  }

  &[data-mode="cursor"] {
    top: 0;
    left: 0;
    margin-top: 12px;
    margin-left: 12px;
  }

  &[data-mode="below"] {
    top: 100%;
    left: 0;
    transform: none !important; /* Override inline styles applied by JS */
  }
}
