/* Bleed out of parent container */
.full-bleed-container {
  transform: translateX(-50%);
  margin-left: 50%;
  width: 100vw;
  overflow-x: clip;
}

/**
 * 1: eg 10 slide, 300px scroll per slide = 3000px high scrolling.
 * 2: what <main> is set to.
 */
.container {
  --_height-unit: 400px;

  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-inline: auto;
  padding-inline: var(--container-padding-inline);
  width: 100%;
  max-width: var(--container-max-width); /* 2 */
  height: calc(var(--_js-height-multiplier) * var(--_height-unit)); /* 1 */

  @media (min-width: 768px) {
    column-gap: 72px;
  }
}

.headline {
  margin-bottom: 18px;
  font-weight: normal;
  font-size: var(--fluid-font-size-base);
  line-height: var(--line-height-base);
}

/*
 * Sticky wrapper, we the height of via JS and offset this so it's center algined to the browser height.
 */
.sticky {
  display: flex;
  position: sticky;
  top: calc(50% - (var(--_js-height-offset) / 2 * 1px));
  flex-direction: column;
  justify-content: center;
}

.scroller {
  display: flex;
  column-gap: 32px;
  flex-wrap: nowrap;
}

.item {
  /** For sticker positioning, tooltip positioning, and for the link's clickable
   * area to cover the item
   */
  position: relative;
  flex: 1 0 auto;
  isolation: isolate;
  width: 80vw;

  @media (min-width: 768px) {
    width: min(42vw, 740px);
  }
}

.link {
  display: block;
  position: absolute;
  z-index: 1; /* Bring to top */
  inset: 0 0 0 0;
}

.item:has(:is(:hover, :active, :focus)) .image {
  opacity: 0.6;
}

.sticker {
  display: none;
  position: absolute;
  margin: 20px;

  @media (min-width: 768px) {
    display: unset;
  }

  &[data-align="bottom-right"] {
    inset: auto 0 0 auto;
  }

  &[data-align="top-right"] {
    inset: 0 0 auto auto;
  }

  &[data-align="top-left"] {
    inset: 0 auto auto 0;
  }

  &[data-align="bottom-left"] {
    inset: auto auto 0 0;
  }
}

/**
 * On mobile this isn't actually a tooltip, it's just a heading beneath the 
 * image. Only at larger viewports does it actually take on "tooltip" behaviour.
 */
.tooltip {
  --_transform: var(--transform);

  margin-top: 4px;
  max-width: 200px;
  font: var(--font-primary);

  @media (min-width: 768px) {
    position: absolute;
    transform: var(--_transform);
    opacity: 0;
    z-index: 1;
    transition: opacity 400ms ease;
    will-change: transform;
    margin-top: unsert;
    background-color: white;

    .item:has(a:is(:hover, :active, :focus)) & {
      opacity: 1;
    }

    &[data-mode="cursor"] {
      top: 0;
      left: 0;
      margin-top: 12px;
      margin-left: 12px;
    }

    &[data-mode="below"] {
      top: 100%;
      left: 0;
      transform: translate3d(0, 0, 0) !important; /* Override inline styles applied by JS */
    }
  }
}
